import { useContext, useEffect, useState } from "react"
import cn from "classnames"

import LikeIcon from "../../../assets/icons/like.svg"
import DislikeIcon from "../../../assets/icons/dislike.svg"

import styles from "./Rating.module.scss"
import { Meme } from "../../../types/meme"
import axios from "axios"
import { firebase } from "../../../lib/firebase"
import { UserContext } from "../../../context/UserContext"

export const Rating: React.FC<{
  meme: Meme
}> = ({ meme }) => {
  const totalVotes = meme.nrOfLikes + meme.nrOfDislikes
  const initialRating =
    totalVotes > 0 ? Math.floor((meme.nrOfLikes / totalVotes) * 100) : 0

  const [rating, setRating] = useState<number>(initialRating)
  const [userAction, setUserAction] = useState<"liked" | "disliked" | null>(
    null,
  )
  const { user } = useContext(UserContext)
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchUserAction = async () => {
      try {
        const token = await firebase.getToken()
        const headers = {
          Authorization: `Bearer ${token}`,
        }
        // Make an API call to retrieve user's vote
        const response = await axios.get(
          `${apiUrl}/image/` + meme.uid + "/vote?",
          { headers },
        )
        const userVote = response.data

        setUserAction(() => userVote)
      } catch (error) {
        console.error("Error fetching user vote:", error)
      }
    }
    fetchUserAction()
  }, [meme.uid, userAction, apiUrl])

  // Does not handle a "direct" switch from a dislike to a like and vice versa
  const likeHandler = async () => {
    const token = await firebase.getToken()
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    if (userAction === "liked") {
      const newLikes = meme.nrOfLikes
      const newTotalVotes = newLikes + meme.nrOfDislikes
      const newRating =
        newTotalVotes > 0 ? Math.floor((newLikes / newTotalVotes) * 100) : 0

      setRating(newRating)

      const response = await axios.post(
        `${apiUrl}/image/` + meme.uid + "/like",
        "",
        { headers },
      )
      setUserAction(response.data)
    } else if (userAction !== "disliked") {
      const newLikes = meme.nrOfLikes + 1
      const newTotalVotes = newLikes + meme.nrOfDislikes
      const newRating =
        newTotalVotes > 0 ? Math.floor((newLikes / newTotalVotes) * 100) : 0

      setRating(newRating)

      const response = await axios.post(
        `${apiUrl}/image/` + meme.uid + "/like",
        "",
        { headers },
      )
      setUserAction(response.data)
    }
  }

  const dislikeHandler = async () => {
    const token = await firebase.getToken()
    const headers = {
      Authorization: `Bearer ${token}`,
    }

    if (userAction === "disliked") {
      const newLikes = meme.nrOfLikes
      const newTotalVotes = newLikes + meme.nrOfDislikes
      const newRating =
        newTotalVotes > 0 ? Math.floor((newLikes / newTotalVotes) * 100) : 0

      setRating(newRating)

      const response = await axios.post(
        `${apiUrl}/image/` + meme.uid + "/dislike",
        "",
        { headers },
      )
      // Should reset to null if already disliked
      setUserAction(response.data)
    } else if (userAction !== "liked") {
      const newLikes = meme.nrOfLikes
      const newTotalVotes = newLikes + meme.nrOfDislikes + 1
      const newRating =
        newTotalVotes > 0 ? Math.floor((newLikes / newTotalVotes) * 100) : 0

      setRating(newRating)

      const response = await axios.post(
        `${apiUrl}/image/` + meme.uid + "/dislike",
        "",
        { headers },
      )
      setUserAction(response.data)
    }
  }

  return (
    <div className={styles.container}>
      <button
        className={cn([styles.button, styles.button_dislike])}
        onClick={e => {
          e.preventDefault()
          !user ? (window.location.href = "/login") : dislikeHandler()
        }}
        style={{ opacity: userAction === "liked" ? 0.4 : 1 }}
      >
        <img src={DislikeIcon} alt="dislike" />
      </button>
      <div
        className={styles.bar}
        style={{
          width: `${rating}%`,
        }}
      >
        <span className={styles.likePercentage}>{rating}% </span>
        {/* <span className={styles.dislikePercentage}>{100 - rating}%</span> */}
      </div>
      <button
        className={cn([styles.button, styles.button_like])}
        onClick={e => {
          e.preventDefault()
          !user ? (window.location.href = "/login") : likeHandler()
        }}
        style={{ opacity: userAction === "disliked" ? 0.4 : 1 }}
      >
        <img src={LikeIcon} alt="like" />
      </button>
    </div>
  )
}
