import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"

import { MemeCard } from "../MemeCard/MemeCard"
import { MemeStats } from "../MemeStats/MemeStats"
import { Meme } from "../../../types/meme"
import styles from "./SingleMeme.module.scss"
import MemeCardSkeleton from "../MemeCardSkeleton/MemeCardSkeleton"

export const SingleMeme: React.FC = () => {
  let { imageid } = useParams()
  const [meme, setMeme] = useState<Meme>()
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    const fetchMeme = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/image/${imageid}?code=6zMDh54WZB9lNsWb88msMAmT4dPfjpr0p1mcks9CcMYPAzFuNiPnrA==`,
        )
        setMeme(response.data)
      } catch (error) {
        console.error("Error fetching meme:", error)
      }
    }
    fetchMeme()
  }, [imageid, apiUrl])

  return (
    <div className={styles.bg}>
      <div className={styles.wrapper}>
        {meme ? (
          <>
            <MemeCard meme={meme!} />
            <MemeStats meme={meme!} />
          </>
        ) : (
          <div className={styles.loading}>
            <MemeCardSkeleton cards={1} />
          </div>
        )}
      </div>
    </div>
  )
}
