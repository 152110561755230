import React, { useState } from "react"
import { Button } from "../Button/Button"
import styles from "./ShareModal.module.scss"

interface ShareModalProps {
  isOpen: boolean
  onClose: () => void
  title: string
  memeUid: string
}

const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  onClose,
  title,
  memeUid,
}) => {
  const [isCopied, setIsCopied] = useState(false)
  //const currentUrl = window.location.href;    // Mer dynamiskt men Share-knappen ska användas från olika urls så det blir wack.

  if (!isOpen) return null

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose() // Close the modal if clicked on the overlay (outside modal)
    }
  }

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(
        "https://mema-dev-94da2.web.app/gallery/" + memeUid,
      )
      setIsCopied(true)
      console.log(
        "Text copied to clipboard:",
        "https://mema-dev-94da2.web.app/gallery/" + memeUid,
      )

      setTimeout(() => {
        setIsCopied(false)
      }, 2000)
    } catch (error) {
      console.error("Failed to copy URL:", error)
    }
  }

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>{title}</h2>
        </div>
        <div className="modalContent">
          <Button
            text="Copy Link"
            className={styles.button}
            onClick={handleCopyClick}
          />
        </div>
        {isCopied && <span style={{ color: "darkgreen" }}>Copied!</span>}
      </div>
    </div>
  )
}

export default ShareModal
