import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { firebase } from '../../../lib/firebase'
import { Button } from '../../common/Button/Button'
import { Input } from '../../common/Input/Input'
import styles from "./Signup.module.scss"

export const SignupForm: React.FC = () => {
  const navigate = useNavigate()

  const submitHandler = async (event: React.BaseSyntheticEvent) => {
    event.preventDefault()

    const { email: emailInput, password: passwordInput } = event.target.elements 
    const email = emailInput.value
    const password = passwordInput.value

    try {
      await firebase.createUser({ email, password })
      navigate("/")
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form onSubmit={submitHandler}>
      <label className={styles.field}>
        Email
        <Input type="text" name="email" />
      </label>
      <label className={styles.field}>
        Password
        <Input type="password" name="password" />
      </label>
      <Button
        className={styles.button}
        text="Signup"
        type="primary"
        htmlType="submit"
      />
      <p className={styles.note}>
        Already have an account? Please <Link to="/login">log in</Link>.
      </p>
    </form>
  )
}