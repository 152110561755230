import React, { useCallback } from "react"
import { loadStripe } from "@stripe/stripe-js"
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js"
import { firebase } from "../../../lib/firebase"
import styles from "./StripeCheckout.module.scss"
import axios from "axios"

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
)

const StripeCheckout = () => {
  const apiUrl = process.env.REACT_APP_API_URL
  const fetchClientSecret = useCallback(async () => {
    try {
      const token = await firebase.getToken()

      axios.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${token}`
        return config
      })

      const response = await axios.post(`${apiUrl}/checkoutsession`)

      return response.data.clientSecret
    } catch (error) {
      console.error("Error fetching client secret:", error)
    }
  }, [apiUrl])

  const options = { fetchClientSecret }

  return (
    <div className={styles.wrapper}>
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </div>
  )
}

export default StripeCheckout
